const navConfig = [
  {
    href: '/privacy',
    text: 'Privacy Policy',
  },
  {
    href: '/terms',
    text: 'Terms and Conditions',
  },
  {
    href: '/cookie-policy',
    text: 'Cookie Policy',
  },
  {
    href: '/refund-policy',
    text: 'Refund Policy',
  },
];

export { navConfig };
