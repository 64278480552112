import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import NotFound from "../components/NotFound";
import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import CookiePolicy from "../pages/CookiePolicy";
import RefundPolicy from "../pages/RefundPolicy";
import { ScrollToTopPage } from "../utils/scrollToElement";

const RootRouter = () => {
  return (
    <>
      <ScrollToTopPage />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route>
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Route>
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
      </Routes>
    </>
  );
};

export default RootRouter;
