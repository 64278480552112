import React from 'react';
import Policy from "../components/Policy";
import data from "../components/Policy/cookie.json";
import {Helmet} from "react-helmet-async";

const CookiePolicy = () => {
  return (
    <>
      <Helmet>
        <title>{data.pageTitle}</title>
        <meta name="description" content={data.pageDescription} />
      </Helmet>
      <Policy pageDate={data} />
    </>
  );
};

export default CookiePolicy;